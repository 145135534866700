import { default as indexGNFEMaQHWJMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/account/custom/index.vue?macro=true";
import { default as packagesWIdqUkdQ54Meta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/account/custom/packages.vue?macro=true";
import { default as partsy6uTARMRZNMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/account/custom/parts.vue?macro=true";
import { default as customkI5L5eX1pDMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/account/custom.vue?macro=true";
import { default as indexKoipXVwODpMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/account/index.vue?macro=true";
import { default as ratesB4V9zEjUFSMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/account/rates.vue?macro=true";
import { default as subscriptionlGNXuVMPKGMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/account/subscription.vue?macro=true";
import { default as accountFaiABAGaoEMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/account.vue?macro=true";
import { default as _91id_93FRitUDixklMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/activite/clients/edit/[id].vue?macro=true";
import { default as indexuoDXeKkpgTMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/activite/clients/index.vue?macro=true";
import { default as _91id_93ALTx1SMKebMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/activite/clients/show/[id].vue?macro=true";
import { default as _91id_93jaoMNmKcORMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/activite/commandes/[id].vue?macro=true";
import { default as indexoFZ64suGVaMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/activite/commandes/index.vue?macro=true";
import { default as vehiculesd5qYBGXJI0Meta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/activite/vehicules.vue?macro=true";
import { default as _91title_93_45_91id_935UCm6iTj9KMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/boutique/[title]-[id].vue?macro=true";
import { default as indexMazIvStxH1Meta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/boutique/index.vue?macro=true";
import { default as productspHXJnl0ElnMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/boutique/products.vue?macro=true";
import { default as indexfU7IgeGODGMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/catalog/index.vue?macro=true";
import { default as configuration9YZkzhog23Meta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/catalog/maintenance/configuration.vue?macro=true";
import { default as indexnKQRTjsqvDMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/catalog/maintenance/index.vue?macro=true";
import { default as plandUy2X9qSqJMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/catalog/maintenance/plan.vue?macro=true";
import { default as works0qlKJo5X1MMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/catalog/maintenance/works.vue?macro=true";
import { default as maintenanceQ36L3MD9wVMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/catalog/maintenance.vue?macro=true";
import { default as manualsn8kobFga2BMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/catalog/manuals.vue?macro=true";
import { default as times3f0Ttk55OjMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/catalog/times.vue?macro=true";
import { default as catalogbImnjgExuMMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/catalog.vue?macro=true";
import { default as commandeigqVKeZv9VMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/commande.vue?macro=true";
import { default as _91tab_937mc9ApVZhLMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/devis/[tab].vue?macro=true";
import { default as _91id_93eFwQaj0kNxMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/devis/edit/[id].vue?macro=true";
import { default as _91id_93DdiXg5JSEFMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/devis/show/[id].vue?macro=true";
import { default as imprimer_45pdfyddUgMy7tnMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/imprimer-pdf.vue?macro=true";
import { default as indexNYvS8KhRqcMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/index.vue?macro=true";
import { default as loginVWNButZ6HCMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/login.vue?macro=true";
import { default as panieriodqr431bXMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/panier.vue?macro=true";
import { default as indexbVKwIquKStMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/pneumatique/index.vue?macro=true";
import { default as products8O0OJb5hxqMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/pneumatique/products.vue?macro=true";
import { default as pneumatiquerbk1vHEYDMMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/pneumatique.vue?macro=true";
import { default as maintenanceja3SyDfGEcMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/print/maintenance.vue?macro=true";
import { default as productsww9lWoDwvhMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/products.vue?macro=true";
import { default as signup7qDqNhMA34Meta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/signup.vue?macro=true";
import { default as tableau_45de_45bordDJ10m5fNNVMeta } from "E:/vhost/lysibox.com/dev-app.lysibox.com/pages/tableau-de-bord.vue?macro=true";
export default [
  {
    name: accountFaiABAGaoEMeta?.name,
    path: "/account",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/account.vue"),
    children: [
  {
    name: customkI5L5eX1pDMeta?.name,
    path: "custom",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/account/custom.vue"),
    children: [
  {
    name: "account-custom",
    path: "",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/account/custom/index.vue")
  },
  {
    name: "account-custom-packages",
    path: "packages",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/account/custom/packages.vue")
  },
  {
    name: "account-custom-parts",
    path: "parts",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/account/custom/parts.vue")
  }
]
  },
  {
    name: "account",
    path: "",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/account/index.vue")
  },
  {
    name: "account-rates",
    path: "rates",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/account/rates.vue")
  },
  {
    name: "account-subscription",
    path: "subscription",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/account/subscription.vue")
  }
]
  },
  {
    name: "activite-clients-edit-id",
    path: "/activite/clients/edit/:id()",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/activite/clients/edit/[id].vue")
  },
  {
    name: "activite-clients",
    path: "/activite/clients",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/activite/clients/index.vue")
  },
  {
    name: "activite-clients-show-id",
    path: "/activite/clients/show/:id()",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/activite/clients/show/[id].vue")
  },
  {
    name: "activite-commandes-id",
    path: "/activite/commandes/:id()",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/activite/commandes/[id].vue")
  },
  {
    name: "activite-commandes",
    path: "/activite/commandes",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/activite/commandes/index.vue")
  },
  {
    name: "activite-vehicules",
    path: "/activite/vehicules",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/activite/vehicules.vue")
  },
  {
    name: "boutique-title-id",
    path: "/boutique/:title()-:id()",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/boutique/[title]-[id].vue")
  },
  {
    name: "boutique",
    path: "/boutique",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/boutique/index.vue")
  },
  {
    name: "boutique-products",
    path: "/boutique/products",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/boutique/products.vue")
  },
  {
    name: catalogbImnjgExuMMeta?.name,
    path: "/catalog",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/catalog.vue"),
    children: [
  {
    name: "catalog",
    path: "",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/catalog/index.vue")
  },
  {
    name: maintenanceQ36L3MD9wVMeta?.name,
    path: "maintenance",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/catalog/maintenance.vue"),
    children: [
  {
    name: "catalog-maintenance-configuration",
    path: "configuration",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/catalog/maintenance/configuration.vue")
  },
  {
    name: "catalog-maintenance",
    path: "",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/catalog/maintenance/index.vue")
  },
  {
    name: "catalog-maintenance-plan",
    path: "plan",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/catalog/maintenance/plan.vue")
  },
  {
    name: "catalog-maintenance-works",
    path: "works",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/catalog/maintenance/works.vue")
  }
]
  },
  {
    name: "catalog-manuals",
    path: "manuals",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/catalog/manuals.vue")
  },
  {
    name: "catalog-times",
    path: "times",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/catalog/times.vue")
  }
]
  },
  {
    name: "commande",
    path: "/commande",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/commande.vue")
  },
  {
    name: "devis-tab",
    path: "/devis/:tab()",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/devis/[tab].vue")
  },
  {
    name: "devis-edit-id",
    path: "/devis/edit/:id()",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/devis/edit/[id].vue")
  },
  {
    name: "devis-show-id",
    path: "/devis/show/:id()",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/devis/show/[id].vue")
  },
  {
    name: "imprimer-pdf",
    path: "/imprimer-pdf",
    meta: imprimer_45pdfyddUgMy7tnMeta || {},
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/imprimer-pdf.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginVWNButZ6HCMeta || {},
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/login.vue")
  },
  {
    name: "panier",
    path: "/panier",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/panier.vue")
  },
  {
    name: pneumatiquerbk1vHEYDMMeta?.name,
    path: "/pneumatique",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/pneumatique.vue"),
    children: [
  {
    name: "pneumatique",
    path: "",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/pneumatique/index.vue")
  },
  {
    name: "pneumatique-products",
    path: "products",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/pneumatique/products.vue")
  }
]
  },
  {
    name: "print-maintenance",
    path: "/print/maintenance",
    meta: maintenanceja3SyDfGEcMeta || {},
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/print/maintenance.vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/products.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signup7qDqNhMA34Meta || {},
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/signup.vue")
  },
  {
    name: "tableau-de-bord",
    path: "/tableau-de-bord",
    component: () => import("E:/vhost/lysibox.com/dev-app.lysibox.com/pages/tableau-de-bord.vue")
  }
]